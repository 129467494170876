import React, {useEffect, useState} from 'react'
import {graphql} from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import {metadata} from '../../config'
import Navigate from '../components/navigate'
import {
  adDisplayDelay,
  delayMultiplicationFactor,
  getArticleLinks,
  setStreamData
} from '../utils/articleUtil'
import CategoryTitleBordered from '../components/helpers/categoryTitleBordered'
import {isMobile} from 'react-device-detect'
import {map as _map} from 'lodash'
import {GatsbyImage} from 'gatsby-plugin-image'
import {homePageAds} from '../utils/adUnits'

const ArticlePreview = loadable(() => import("./articlePreview"));

const Homepage = props => {
    const displayedArticles = [];
    const [isMobileDisplay, setIsMobileDisplay] = useState(false);
    useEffect(() => {
        setIsMobileDisplay(isMobile);
        /*const timeout = setTimeout(() => {
          homePageAds();
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));*/
      const timeout = setTimeout(homePageAds, adDisplayDelay() * delayMultiplicationFactor(isMobile));
      return () => {
        clearTimeout(timeout);
      };
    }, [isMobile])

    const getHeroArticle = () => {
        let featuredArticle = (props.data.featured.nodes || []).find(article => new Date(article['featuredTill']) >= new Date()) || props.data.editorsChoice.nodes[0];
        if (featuredArticle) {
          let category = featuredArticle.category.find(item => !!item.treeParent)
          featuredArticle.categoryTitle = category?.treeParent?.title;
          displayedArticles.push(featuredArticle.id);
        }
        return featuredArticle;
    };

    const [heroArticle, setHeroArticle] = useState(getHeroArticle());

    const schemaArticle = {
        '@context': 'https://schema.org',
        '@type': 'Website',
        "url": metadata.url,
        "name": metadata.defaultTitle,
        "description": metadata.description,
        "potentialAction": {
            "@type": "SearchAction",
            "target": `${metadata.url}/search/{search_term_string}`,
            "query-input": "required name=search_term_string"
        },
        "publisher": {
          "@type": "Organization",
          "name": metadata.defaultTitle,
          "logo": {
            "@type": "ImageObject",
            "url": `${metadata.url}/images/logos/logo.jpeg`
          }
        }
    }

    setStreamData({
        category: undefined,
        subCategory: undefined,
        article: undefined
    })

    const excludeAlreadyDisplayed = (articles) => {
        return articles.filter(article => !displayedArticles.includes(article.id));
    }

    return (
        <Layout>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>
            </Helmet>
            <main className={"wrapper lg:mx-auto main-data-container"} data-datocms-noindex>
                <div className={"lg:flex mb-4"}>
                    <Navigate className={"flex-1"} href={getArticleLinks(heroArticle).getReadMore()}>
                        <div className={"flex flex-col"}>
                            <div className={"lg:text-left cursor-pointer flex items-end justify-end relative"}>
                                <GatsbyImage className={"h-[361px] w-full"} alt={heroArticle.heading} image={heroArticle.cover.gatsbyImageData}/>
                                <div className={"px-8 text-sm font-bold bg-gray-100 absolute"} style={{left: 0}}>{heroArticle.categoryTitle}</div>
                            </div>
                            <div
                                className={"text-black hero-article-title text-2xl lg:text-2xl mt-3 font-bold leading-snug font-sans text-center lg:text-left"}>
                                {heroArticle.heading}
                            </div>
                            <div
                                className={"hidden hero-article-excerpt text-gray-700 text-lg mt-1 leading-snug font-helvetica"}>
                                {heroArticle.excerpt}
                            </div>
                        </div>
                    </Navigate>
                    {!isMobileDisplay && <div className={"hidden max-w-[632px] h-[361px] lg:flex flex-col justify-between p-8 bg-gray-100 flex-1 ml-4"}>
                        {
                            excludeAlreadyDisplayed(props.data.topRight.nodes).slice(0,3).map((article, index) => {
                                displayedArticles.push(article.id);
                                return <ArticlePreview
                                    key={`top-right-${index}`} data={article}
                                    className="flex w-full"
                                    imageClass="mb-0 mr-2 float-left clear-both"
                                    gatsbyImageClass="w-[70px] h-full"
                                    headingClass="font-sans font-bold leading-snug text-xl mb-0 line-clamp l-c-2"
                                    noFlag={true}
                                    noBody={true}
                                    noAuthor={true}
                                    wrappedImg={false}
                                    dateAsFlag={true}
                                    onlyAuthor={true}
                                    titleAuthorDisplayClass={"flex-col-reverse"}
                                    publishedAtFormat={"mmmm dd, yyyy"}/>
                            })
                        }
                    </div>}
                </div>
              <div className="ad-container mb-2" data-datocms-noindex>
                <div className="advertisement-text">Advertisement</div>
                <div id="ht_incontent1"></div>
              </div>
                {/*{!isMobileDisplay && <div className={"hidden lg:block mb-8 lg:mb-16"}>
                    <CategoryTitleBordered title={"NEWS"}/>
                    <div className={"flex"}>
                        {
                            excludeAlreadyDisplayed(props.data.news.nodes).slice(0, 3).map((article, index) => {
                                displayedArticles.push(article.id);
                                return <ArticlePreview
                                    key={`news-${index}`} data={article}
                                    className={`flex-1 first:ml-0 last:mr-0 mx-2 ${index === 2 ? 'hidden md:block' : ''}`}
                                    headingClass={"text-xl font-arial font-semibold sm:font-bold leading-snug"}
                                    noBody={true}
                                    noFlag={true}
                                    noAuthor={true}
                                    dateAsFlag={true}
                                    publishedAtFormat={"mmmm dd, yyyy"}/>
                            })
                        }
                    </div>
                </div>}*/}
                <div className={"grid grid-cols-2 gap-10 my-8"}>
                    {_map(props.pageContext.indexPageCategoryWiseArticles, (data, key) => {
                        return <div className={"col-span-2 lg:col-span-1"} key={key}>
                            <CategoryTitleBordered title={data.title} link={`/${key}`} className={"uppercase mb-4 lg:mb-6"}/>
                            <div className={"flex px-2 lg:px-0"}>
                                {
                                    excludeAlreadyDisplayed(data.articles).slice(0, 2).map((article, index) => {
                                        displayedArticles.push(article.id);
                                        return <ArticlePreview
                                            key={`news-${index}`} data={article}
                                            className={`flex-1 first:ml-0 last:mr-0 mx-2 ${index === 2 ? 'hidden md:block' : ''}`}
                                            headingClass={"text-xl font-arial font-semibold sm:font-bold leading-snug mt-1"}
                                            noBody={true}
                                            noFlag={true}
                                            noAuthor={true}
                                            dateAsFlag={true}
                                            publishedAtFormat={"mmmm dd, yyyy"}/>
                                    })
                                }
                            </div>
                        </div>
                    })}
                </div>
              <div className="ad-container mb-2" data-datocms-noindex>
                <div className="advertisement-text">Advertisement</div>
                <div id="ht_incontent2"></div>
              </div>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query indexPageTopLeft($top: [String], $currentDateTime: Date) {
        featured: allDatoCmsArticle(filter: {featuredTill: {gte: $currentDateTime}, website: {elemMatch: {name: {eq: "ht"}}}}
            sort: {order: DESC, fields: publishedAt}
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
        editorsChoice: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {in: $top}}}, website: {elemMatch: {name: {eq: "ht"}}}}
            sort: {order: DESC, fields: publishedAt}
            limit: 1
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
        topRight: allDatoCmsArticle(filter: {category: {elemMatch: {slug: {regex: "/^((?!-video).)*$/"}}}, website: {elemMatch: {name: {eq: "ht"}}}}
            sort: {order: DESC, fields: publishedAt}
            limit: 4
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`

export default Homepage
